/* Styles généraux */
.container {
  padding: 20px;
  margin: 0 auto;
  max-width: 800px; /* Limite la largeur pour une meilleure lisibilité */
  font-family: Arial, sans-serif;
  line-height: 1.6;
  text-align: left; /* Aligne tout le contenu à gauche */
}

/* Titre principal */
.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center; /* Centré pour le titre principal */
  color: #EFF5FF;
}

/* Sous-titres */
.subtitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #EFF5FF;
}

/* Texte principal */
.text {
  font-size: 1rem;
  margin-bottom: 15px;
  color: #EFF5FF;
}

/* Liste */
ul {
  padding-left: 20px; /* Indentation pour les listes */
  margin-bottom: 20px;
}

/* Bouton de navigation */
.button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  text-align: center;
}

.button:hover {
  background-color: #0056b3;
}